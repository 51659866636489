import { ApplicationController } from "../shared/application-controller";
import {
  DOMHelper,
  HTTPCacheType,
  HTTPHelper,
} from "@veridapt/browser-helpers";
import { Popover } from "bootstrap";

export default class extends ApplicationController {
  static values = { detailsSrc: String };
  dom: DOMHelper | undefined;
  http: HTTPHelper | undefined;
  popover: Popover | undefined;

  popoverEventListener = (): void => {
    void this.updateDetailsContent();
  };

  connect(): void {
    this.dom = this.useDOM();
    this.http = this.useHTTP({ cache: HTTPCacheType.Force });
    this.initialiseDetailsPopover();
  }

  disconnect(): void {
    this.destroyDetailsPopover();
  }

  private initialiseDetailsPopover() {
    if (!this.popover) {
      void (async () => {
        const loadingIndicator = await this.dom?.buildFragment(
          "<v-loading-indicator class='d-block text-primary'></v-loading-indicator>"
        );

        if (loadingIndicator) {
          this.popover = new Popover(this.element, {
            boundary: "viewport",
            container: "body",
            content: loadingIndicator.cloneNode(true) as Element,
            html: true,
            placement: "right",
            trigger: "hover",
          });
        }
      })();

      this.element.addEventListener(
        "shown.bs.popover",
        this.popoverEventListener
      );
    }
  }

  private destroyDetailsPopover() {
    if (this.popover) {
      this.popover.hide();
      this.popover.dispose();
      delete this.popover;

      this.element.removeEventListener(
        "shown.bs.popover",
        this.popoverEventListener
      );
    }
  }

  private getDetailsElement(): Element | null {
    if (this.popover) {
      const getTipElement = Reflect.get(this.popover, "getTipElement") as {
        (): Element;
      };
      const tipElement = getTipElement.call(this.popover);
      return tipElement?.querySelector(".popover-body");
    }
    return null;
  }

  private async updateDetailsContent() {
    const detailsSrc = Reflect.get(this, "detailsSrcValue") as
      | string
      | undefined;
    if (detailsSrc) {
      const detailsElement = this.getDetailsElement();
      if (detailsElement) {
        const responseText = await this.http?.get(detailsSrc);
        detailsElement.innerHTML = responseText ?? "";
        this.popover?.update();
      }
    }
  }
}
