import { ApplicationController } from "../shared/application-controller";
import {
  DOMHelper,
  HTTPHelper,
  HTTPRequestMethod,
} from "@veridapt/browser-helpers";

export default class extends ApplicationController {
  dom: DOMHelper | undefined;
  http: HTTPHelper | undefined;

  connect(): void {
    this.dom = this.useDOM({ context: this.element });
    this.http = this.useHTTP({ context: this.element });
  }

  load(event: MouseEvent): void {
    void (async () => {
      const content = await this.requestContent(event);
      if (typeof content == "string") {
        return this.handleResponseContent(content);
      }
    })();
    this.cancel(event);
  }

  cancel(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  private async requestContent(
    event: MouseEvent
  ): Promise<string | null | undefined> {
    if (this.http) {
      const { dataset, href } = event.target as HTMLAnchorElement;
      const method =
        (dataset.method as HTTPRequestMethod) ?? HTTPRequestMethod.Get;
      return await this.http[method](href);
    }
  }

  private async handleResponseContent(content: string): Promise<void> {
    if (this.dom) {
      const fragment = await this.dom.handleResponseContent(content);
      if (fragment) {
        this.dom.replaceElement(this.element, fragment);
      }
    }
  }
}
