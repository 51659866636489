import { Helper } from "@veridapt/core";
import { useEvents } from "..";

export type HistoryQueryState = Record<string, string>;
export type HistoryQueryStateListener = (state: HistoryQueryState) => void;

export interface HistoryQueryHelper extends Helper {
  get: () => HistoryQueryState;
  onPop: (listener: HistoryQueryStateListener) => void;
  push: (state: HistoryQueryState) => void;
  replace: (state: HistoryQueryState) => void;
}

export const useHistoryQuery = (): HistoryQueryHelper => {
  const events = useEvents<WindowEventMap>(window);

  const addQueryListener = (listener: HistoryQueryStateListener) => {
    events.on("popstate", (event: PopStateEvent) => {
      listener(event.state);
    });
  };

  const buildQueryState = (query: string): HistoryQueryState => {
    const params = new URLSearchParams(query);
    const state: HistoryQueryState = {};
    params.forEach((value, key) => {
      state[key] = value;
    });
    return state;
  };

  const buildQueryString = (state: HistoryQueryState): string => {
    const params = new URLSearchParams(state);
    return `?${params.toString()}`;
  };

  const clearQueryListeners = () => {
    events.clear();
  };

  const getQuery = (): HistoryQueryState => {
    return (
      (history.state as HistoryQueryState) ?? buildQueryState(location.search)
    );
  };

  const pushQuery = (state: HistoryQueryState): void => {
    const query = buildQueryString(state);
    history.pushState(state, "", query);
  };

  const replaceQuery = (state: HistoryQueryState): void => {
    const query = buildQueryString(state);
    history.replaceState(state, "", query);
  };

  return {
    destroy: clearQueryListeners,
    get: getQuery,
    onPop: addQueryListener,
    push: pushQuery,
    replace: replaceQuery,
  };
};
