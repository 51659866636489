import { ApplicationController } from "../shared/application-controller";
import { Tab } from "bootstrap";
import {
  HistoryQueryHelper,
  HistoryQueryState,
  HTTPCacheType,
  HTTPHelper,
  useHistoryQuery,
} from "@veridapt/browser-helpers";

export default class extends ApplicationController {
  static classes = ["activeTab", "inactiveTab"];
  static targets = ["tab", "tabPanel", "tabPanelPlaceholder"];
  static values = { activeTabParam: String };

  declare readonly activeTabClass: string | undefined;
  declare readonly activeTabParamValue: string | undefined;
  declare readonly inactiveTabClass: string | undefined;
  declare readonly tabPanelPlaceholderTarget: HTMLTemplateElement | undefined;
  declare readonly tabPanelTargets: Element[] | undefined;
  declare readonly tabTargets: Element[] | undefined;

  historyQuery: HistoryQueryHelper | undefined;
  http: HTTPHelper | undefined;

  connect(): void {
    this.historyQuery = useHistoryQuery();
    this.http = this.useHTTP({ cache: HTTPCacheType.Mutex });
    this.connectTabs();
    this.initialiseActiveTab();
    this.initialiseHistoryQuery();
  }

  disconnect(): void {
    this.historyQuery?.destroy();
    this.disconnectTabs();
  }

  handleTabShowEvent: EventListener = (event: Event): void => {
    const tab = event.target as Element | null;
    if (tab) {
      this.updateTabHistory(tab);
      this.updateTabPanel(tab);
    }
  };

  handleTabShownEvent: EventListener = (event: Event): void => {
    const activeTab = event.target as Element | null;
    const previousTab = Reflect.get(event, "relatedTarget") as Element | null;
    this.updateTabClasses(activeTab, previousTab);
  };

  private connectTabs(): void {
    this.tabTargets?.forEach((tab: Element) => {
      tab.addEventListener("show.bs.tab", this.handleTabShowEvent);
      tab.addEventListener("shown.bs.tab", this.handleTabShownEvent);
      new Tab(tab);
    });
  }

  private disconnectTabs(): void {
    this.tabTargets?.forEach((tab: Element) => {
      tab.removeEventListener("show.bs.tab", this.handleTabShowEvent);
      tab.removeEventListener("shown.bs.tab", this.handleTabShownEvent);
      Tab.getInstance(tab)?.dispose();
    });
  }

  private getTabKey(tab: Element | undefined) {
    return tab?.getAttribute("data-remote-tablist-key");
  }

  private initialiseActiveTab(): void {
    if (this.tabTargets) {
      let activeTab: Element | undefined = undefined;
      const defaultActiveTab = this.tabTargets.find((tab) =>
        tab.classList.contains("active")
      );

      if (defaultActiveTab) {
        activeTab = defaultActiveTab;
      }

      if (this.activeTabParamValue) {
        const defaultActiveTabKey = this.getTabKey(defaultActiveTab);
        const activeTabKey: string | undefined = Reflect.get(
          this.historyQuery?.get() ?? {},
          this.activeTabParamValue
        ) as string;

        if (activeTabKey && activeTabKey !== defaultActiveTabKey) {
          const updatedActiveTab = this.tabTargets.find(
            (tab) => activeTabKey === this.getTabKey(tab)
          );

          if (updatedActiveTab) {
            activeTab = updatedActiveTab;
            Tab.getInstance(activeTab)?.show();
            this.updateTabClasses(activeTab, defaultActiveTab);
          }
        }
      }

      if (activeTab) {
        this.updateTabPanel(activeTab);
      }
    }
  }

  private initialiseHistoryQuery() {
    this.historyQuery?.onPop((state: HistoryQueryState) => {
      if (this.activeTabParamValue) {
        const tabKey = Reflect.get(state, this.activeTabParamValue) as string;

        if (tabKey) {
          const tab = this.tabTargets?.find((tab) => {
            tabKey === this.getTabKey(tab);
          });

          if (tab) {
            const previousTab = this.tabTargets?.find((tab) =>
              tab.classList.contains("active")
            );

            Tab.getInstance(tab)?.show();
            this.updateTabClasses(tab, previousTab);
            this.updateTabPanel(tab);
          }
        }
      }
    });
  }

  private updateContent(tabPanel: Element, content: string | null | undefined) {
    const contentFragment = content
      ? document.createRange().createContextualFragment(content)
      : new DocumentFragment();
    this.updateContentFragment(tabPanel, contentFragment);
  }

  private updateContentFragment(
    target: Element,
    fragment: DocumentFragment
  ): void {
    while (target.hasChildNodes()) {
      target.firstChild?.remove();
    }

    target.append(fragment.cloneNode(true));
  }

  private updateTabClasses(
    activeTab: Element | null | undefined,
    previousTab: Element | null | undefined
  ) {
    const activeTabCSSClasses = this.activeTabClass?.split(" ") ?? [];
    const inactiveTabCSSClasses = this.inactiveTabClass?.split(" ") ?? [];

    if (activeTab) {
      activeTab.classList.add(...activeTabCSSClasses);
      activeTab.classList.remove(...inactiveTabCSSClasses);
    }

    if (previousTab) {
      previousTab.classList.add(...inactiveTabCSSClasses);
      previousTab.classList.remove(...activeTabCSSClasses);
    }
  }

  private updateTabHistory(tab: Element) {
    if (this.activeTabParamValue) {
      const key = this.getTabKey(tab);
      if (key) {
        this.historyQuery?.replace({
          [this.activeTabParamValue]: key,
        });
      }
    }
  }

  private updateTabPanel(tab: Element): void {
    const path = tab.getAttribute("formaction");
    const tabPanelID = tab.getAttribute("aria-controls");
    const tabPanel = this.tabPanelTargets?.find(
      (tabPanel) => tabPanel.id === tabPanelID
    );

    if (path && tabPanel) {
      this.updateTabPanelPlaceholder(tabPanel);

      void (async () => {
        const responseText = await this.http?.get(path);
        this.updateContent(tabPanel, responseText);
      })();
    }
  }

  private updateTabPanelPlaceholder(tabPanel: Element) {
    if (this.tabPanelPlaceholderTarget) {
      this.updateContentFragment(
        tabPanel,
        this.tabPanelPlaceholderTarget.content
      );
    }
  }
}
