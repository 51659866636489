import { ApplicationController } from "../shared/application-controller";
import { I18nHelper } from "@veridapt/core";
import {
  BrowserCustomEvents,
  CustomEventListener,
  CustomEventsHelper,
} from "@veridapt/browser-helpers";

const WorkflowControlStateEventType = "v.workflow.control.state";

type WorkflowControlStateEventDetail =
  | {
      id: string;
      closed: boolean;
    }
  | undefined;

export default class extends ApplicationController {
  static values = {
    id: String,
    authorized: Boolean,
    closed: Boolean,
    loading: Boolean,
    modelKey: String,
    parentClosed: Boolean,
    parentId: String,
  };
  declare readonly idValue: string | undefined;
  declare readonly modelKeyValue: string | undefined;
  declare readonly parentIdValue: string | undefined;
  declare authorizedValue: boolean | undefined;
  declare closedValue: boolean | undefined;
  declare loadingValue: boolean | undefined;
  declare parentClosedValue: boolean | undefined;

  private customEvents: CustomEventsHelper | undefined;
  private i18n: I18nHelper | undefined;

  connect(): void {
    this.customEvents = this.useCustomEvents(globalThis);
    this.i18n = this.useI18n();

    this.initialiseCustomEvents();
    this.triggerDeferredStateEvent();
    this.updateParentClosedErrorMessageTooltip();
  }

  loadingValueChanged(): void {
    this.updateDropdownDisabledValue();
  }

  parentClosedValueChanged(): void {
    this.updateDropdownDisabledValue();
    this.updateParentClosedErrorMessageTooltip();
  }

  private initialiseCustomEvents() {
    this.customEvents?.onEach(
      [BrowserCustomEvents.HTTPLoading, this.httpLoadingEventListener],
      [BrowserCustomEvents.HTTPDone, this.httpDoneEventListener],
      [WorkflowControlStateEventType, this.WorkflowControlStateEventListener]
    );
  }

  private httpLoadingEventListener: CustomEventListener = (): void => {
    this.loadingValue = true;
  };

  private httpDoneEventListener: CustomEventListener = (): void => {
    this.triggerDeferredStateEvent();
    this.loadingValue = false;
  };

  private WorkflowControlStateEventListener: CustomEventListener = (
    event: CustomEvent<WorkflowControlStateEventDetail>
  ) => {
    this.handleStateEvent(event.detail?.id, event.detail?.closed);
  };

  private handleStateEvent(sourceID?: string, sourceClosed?: boolean) {
    if (sourceID === this.parentIdValue) {
      this.parentClosedValue = sourceClosed;
    }
  }

  private triggerDeferredStateEvent() {
    setTimeout(() => {
      this.customEvents?.trigger(WorkflowControlStateEventType, {
        id: this.idValue,
        closed: this.closedValue,
      });
    });
  }

  private updateDropdownDisabledValue() {
    const disabled = Boolean(
      !this.authorizedValue || this.loadingValue || this.parentClosedValue
    );
    this.element.setAttribute("data-dropdown-disabled-value", String(disabled));
  }

  private updateParentClosedErrorMessageTooltip() {
    if (this.authorizedValue && this.parentClosedValue) {
      this.element.setAttribute("title", this.parentClosedErrorMessage);
    } else {
      this.element.removeAttribute("title");
    }
  }

  private get parentClosedErrorMessage(): string {
    return String(
      this.i18n?.translate(
        `errors.messages.workflows.${
          this.modelKeyValue as string
        }.parent_closed`
      )
    );
  }
}
