import { dropZoneInit } from "../../javascript/shared/file-drop-zone-init";

const initFileAttachModal: null = (modal: Element) => {
  const form = modal.querySelector("form");
  const submit = modal.querySelector(".documents-submit");
  const filesInput = modal.querySelector(".documents-input");
  // Get file size from data attribute
  const maxFileSize = parseInt(form.dataset["maxFileSize"] || "10");

  const dropzone = dropZoneInit(form, {
    previewsContainer: modal.querySelector(".attach-files-preview"),
    maxFilesize: maxFileSize,
  });

  submit.addEventListener("click", () => {
    dropzone.processQueue();
  });

  filesInput.addEventListener("change", () => {
    const files: File[] = Array.from(filesInput.files);
    for (const file of files) {
      dropzone.addFile(file);
    }
    filesInput.value = null;
  });

  modal.addEventListener("hidden.bs.modal", () => {
    dropzone.removeAllFiles(true);
  });
};

(function () {
  const modals: Element[] = Array.from(
    document.querySelectorAll(".v-modal-file-attach")
  );

  modals.forEach((modal: Element) => {
    initFileAttachModal(modal);
  });
})();
