import { useHTTP } from "@veridapt/browser-helpers";

const dealTypeVariations = document.getElementById("deal_type_variations");
const http = useHTTP();

if (dealTypeVariations) {
  const dataUrl = dealTypeVariations.dataset.url as string;
  const dealTypeSelector = document.getElementById(
    "deal_deal_template_id"
  ) as HTMLSelectElement;

  dealTypeSelector?.addEventListener("change", function () {
    void (async () => {
      const responseText = dealTypeSelector.value
        ? await http.get(dataUrl, { deal_template_id: dealTypeSelector.value })
        : null;
      dealTypeVariations.innerHTML = responseText ?? "";
    })();
  });
}
