import Dropzone from "dropzone";
import { Modal } from "bootstrap";
import { Controller } from "stimulus";
import { dropZoneInit } from "../shared/file-drop-zone-init";

let dropZoneTimer;
const removeFileDragStyles = () => {
  if (dropZoneTimer) clearTimeout(dropZoneTimer);

  dropZoneTimer = setTimeout(() => {
    document.body.classList.remove("document-file-drag-active");
  }, 70); // > 50 ms which is interval of dragover event
};

const dropPageInit = () => {
  window.addEventListener(
    "drop",
    (event: DragEvent) => {
      // when dragged files are dropped not in any dropzone, this will:
      // - stop browser open the dropped file in a new tab
      // - remove all highlights
      event.preventDefault();
      removeFileDragStyles();
    },
    false
  );

  window.addEventListener(
    "dragover",
    (event: DragEvent) => {
      event.preventDefault(); // without this, drop event doesn't fire
      const types: string[] = event.dataTransfer.types as string[];
      // without this check, dragging any element will trigger the drop target UI
      if (types.includes("Files")) {
        if (dropZoneTimer) clearTimeout(dropZoneTimer);
        document.body.classList.add("document-file-drag-active");
      }
    },
    false
  );

  window.addEventListener("dragleave", removeFileDragStyles, false);
};

class FilePreviewModal extends Modal {
  title: string;
  currentDropzone: Dropzone;

  constructor(elm: Element) {
    super(elm);

    elm.addEventListener("hidden.bs.modal", () => {
      if (this.currentDropzone) this.currentDropzone.removeAllFiles();
    });

    elm.addEventListener("show.bs.modal", () => {
      const titleElm = elm.querySelector(".attach-target-title");
      if (titleElm) titleElm.textContent = this.title;
    });

    elm.querySelector("#drops-submit").addEventListener("click", (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (this.currentDropzone) this.currentDropzone.processQueue();
    });
  }

  setTargetTitle(title: string) {
    this.title = title;
  }

  setCurrentZone(dropzone: Dropzone) {
    this.currentDropzone = dropzone;
  }
}

const filePreviewModal: FilePreviewModal = (() => {
  dropPageInit();

  const modalElm = document.getElementById("drop-preview-modal");
  if (modalElm) {
    return new FilePreviewModal(modalElm);
  }
})();

export default class extends Controller {
  static values = { title: String };

  connect(): void {
    this.form = this.element.querySelector("form") as HTMLElement;
    this.dropzone = dropZoneInit(this.form, {
      previewsContainer: document.getElementById("files-preview"),
    });
    this.dropTargetInit(this.form);
  }

  private dropTargetInit(target: HTMLElement): void {
    target.addEventListener(
      "drop",
      (event: DragEvent) => {
        const types: string[] = event.dataTransfer.types as string[];
        if (types.includes("Files")) {
          filePreviewModal.setCurrentZone(this.dropzone);
          filePreviewModal.setTargetTitle(this.titleValue);
          filePreviewModal.show();
        }
      },
      false
    );
  }
}
