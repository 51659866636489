import { Context, Controller } from "stimulus";
import { Helper, I18nHelper, useI18n } from "@veridapt/core";
import {
  CustomEventsHelper,
  DOMHelper,
  DOMHelperOptions,
  HTTPHelper,
  HTTPHelperOptions,
  useCustomEvents,
  useDOM,
  useHTTP,
} from "@veridapt/browser-helpers";

type ControllerLifeCycleMethod = (this: Controller) => void;

export class ApplicationController extends Controller {
  private helpers = new Set<Helper>();

  constructor(context: Context) {
    super(context);

    wrapControllerMethod(this, "disconnect", () => {
      this.destroyHelpers();
    });
  }

  destroyHelpers(): void {
    for (const helper of this.helpers) {
      helper?.destroy();
    }
    this.helpers.clear();
  }

  registerHelper<T extends Helper>(helperFactory: () => T): T {
    const helper = helperFactory();
    this.helpers.add(helper);
    return helper;
  }

  useCustomEvents(target: EventTarget): CustomEventsHelper {
    return this.registerHelper(() => useCustomEvents(target));
  }

  useDOM(options?: DOMHelperOptions): DOMHelper {
    return this.registerHelper(() => useDOM(options));
  }

  useHTTP(options?: HTTPHelperOptions): HTTPHelper {
    return this.registerHelper(() => useHTTP(options));
  }

  useI18n(): I18nHelper {
    return this.registerHelper(() => useI18n());
  }
}

function wrapControllerMethod(
  target: Controller,
  name: PropertyKey,
  overrideMethod: ControllerLifeCycleMethod
) {
  const initialMethod = Reflect.get(target, name) as ControllerLifeCycleMethod;
  Reflect.set(target, name, () => {
    overrideMethod.call(target);
    initialMethod.call(target);
  });
}
