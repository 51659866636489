import { useHTTP } from "@veridapt/browser-helpers";

const dealProcessTypeVariations = document.getElementById(
  "deal_process_type_variations"
);
const http = useHTTP();

if (dealProcessTypeVariations) {
  const dataUrl = dealProcessTypeVariations.dataset.url as string;
  const dealProcessTypeSelector = document.getElementById(
    "deal_process_process_template_id"
  ) as HTMLSelectElement;

  dealProcessTypeSelector?.addEventListener("change", function () {
    void (async () => {
      const responseText = dealProcessTypeSelector.value
        ? await http.get(dataUrl, {
            process_template_id: dealProcessTypeSelector.value,
          })
        : null;
      dealProcessTypeVariations.innerHTML = responseText ?? "";
    })();
  });
}
