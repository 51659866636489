import { ApplicationController } from "../shared/application-controller";
import { HTTPHelper } from "@veridapt/browser-helpers";

interface FollowResult extends Record<string, unknown> {
  path: string;
  status: string;
  status_text: string;
  following: boolean;
}

export default class extends ApplicationController {
  declare readonly statusTarget: HTMLAnchorElement;
  declare urlValue: string | undefined;
  static targets = ["status"];
  static values = { url: String };
  http: HTTPHelper | undefined;

  connect(): void {
    this.http = this.useHTTP();
  }

  toggle(e: MouseEvent): void {
    e.preventDefault();
    e.stopPropagation();

    void (async () => {
      if (this.urlValue) {
        const result = (await this.http?.postJSON(
          this.urlValue
        )) as FollowResult;
        if (result) {
          this.update(result.path, result.status_text, result.following);
        }
      }
    })();
  }

  private update(path: string, status: string, following: boolean): void {
    this.urlValue = path;
    this.statusTarget.title = status;
    this.toggleIcon(following);
  }

  private toggleIcon(following: boolean): void {
    const icon = this.statusTarget.firstElementChild;
    if (following) {
      icon?.classList.add("bi-star-fill");
      icon?.classList.remove("bi-star");
    } else {
      icon?.classList.add("bi-star");
      icon?.classList.remove("bi-star-fill");
    }
  }
}
