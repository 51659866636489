import { Helper } from "..";

type GlobalPropertyKey = string;
type GlobalPropertyValue = string | (() => string);

let globalProperties: Map<GlobalPropertyKey, GlobalPropertyValue>;

export interface GlobalPropertiesHelper extends Helper {
  clear: () => void;
  get: (key: GlobalPropertyKey) => string | undefined;
}

export const useGlobalProperties = (
  entries?:
    | readonly [GlobalPropertyKey, GlobalPropertyValue][]
    | null
    | undefined
): GlobalPropertiesHelper => {
  globalProperties ??= new Map<GlobalPropertyKey, GlobalPropertyValue>();

  if (Array.isArray(entries)) {
    entries.forEach(([key, value]) => {
      globalProperties.set(key, value);
    });
  }

  const clearValues = () => {
    globalProperties.clear();
  };

  const getValue = (key: GlobalPropertyKey) => {
    const value = globalProperties.get(key);
    if (value) {
      return typeof value === "string" ? value : value();
    }
  };

  return {
    clear: clearValues,
    get: getValue,
    // NB: Support helper life-cycle without erasing global state.
    destroy: () => undefined,
  };
};
