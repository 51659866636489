import { Controller } from "stimulus";
import { Tooltip } from "bootstrap";

type TargetNames = "nav" | "navLink" | "navLinkText";
type TargetClassListUpdateMethodName = "add" | "remove" | "toggle";
type TargetClassListKey = "base" | "baseTransition" | "toggle" | "transition";
enum ViewState {
  Expanded = "expanded",
  Collapsed = "collapsed",
}

const storageViewStateID = "app-nav-view-state";
const targetNames: TargetNames[] = ["nav", "navLink", "navLinkText"];
const targetClasses: {
  [key in TargetNames]: { [key in TargetClassListKey]: string[] };
} = {
  nav: {
    base: [
      "v-mw-0",
      "v-mw-xl-unset",
      "v-opacity-0",
      "v-opacity-xl-unset",
      "v-position-absolute",
      "v-position-xl-unset",
      "v-z-index-1",
    ],
    baseTransition: ["v-transition-unset"],
    toggle: ["v-mw-0", "v-mw-20", "v-opacity-0", "v-opacity-100"],
    transition: ["v-transition-mw-opacity", "v-transition-xl-unset"],
  },
  navLink: {
    base: ["gap-xl-2", "v-opacity-0", "v-opacity-xl-100"],
    baseTransition: ["v-transition-unset"],
    toggle: ["gap-xl-0", "gap-xl-2", "v-opacity-0", "v-opacity-100"],
    transition: ["v-transition-gap-opacity"],
  },
  navLinkText: {
    base: ["v-mw-xl-10", "v-opacity-0", "v-opacity-xl-100"],
    baseTransition: ["v-transition-unset"],
    toggle: [
      "v-mw-xl-0",
      "v-mw-xl-10",
      "v-opacity-0",
      "v-opacity-100",
      "v-opacity-xl-0",
      "v-opacity-xl-100",
    ],
    transition: ["v-transition-mw-opacity"],
  },
};

export default class extends Controller {
  static targets = targetNames;

  tooltips: Tooltip[] | undefined;
  viewState: ViewState | undefined;

  connect(): void {
    this.updateTargetClassLists("add", "base");
    this.initialiseViewState();

    if (!this.isDropdownNav()) {
      this.initialiseTooltips();

      if (this.viewState === ViewState.Collapsed) {
        this.updateTargetClassLists("toggle", "toggle");
      }
    }

    setTimeout(() => {
      this.updateTargetClassLists("remove", "baseTransition");
      this.updateTargetClassLists("add", "transition");
    }, 100);
  }

  disconnect(): void {
    this.updateTargetClassLists("remove", "transition");
    this.updateTargetClassLists("remove", "toggle");
    this.updateTargetClassLists("remove", "base");
    this.updateTargetClassLists("add", "baseTransition");

    if (!this.isDropdownNav()) {
      this.destroyTooltips();
    }
  }

  collapseDropdown(): void {
    if (this.isDropdownNav() && this.viewState === ViewState.Expanded) {
      this.toggleViewState();
    }
  }

  toggle(event: Event): void {
    this.toggleViewState();

    if (!this.isDropdownNav()) {
      this.toggleTooltips();
      this.persistViewState();
    }

    event.stopPropagation();
  }

  private initialiseTooltips() {
    this.tooltips = this.getTargetElements("navLink")?.map((navLinkElement) => {
      const tooltip = new Tooltip(navLinkElement, {
        boundary: "viewport",
        customClass: "fs-5 fw-bold",
        container: "body",
        placement: "right",
        title: navLinkElement.textContent as string,
      });

      if (this.viewState === ViewState.Expanded) {
        tooltip.disable();
      }

      return tooltip;
    });
  }

  private toggleTooltips() {
    const tooltipEnabled = this.viewState === ViewState.Collapsed;
    this.tooltips?.forEach((tooltip) => {
      tooltip[tooltipEnabled ? "enable" : "disable"]();
    });
  }

  private destroyTooltips() {
    this.tooltips?.forEach((tooltip) => {
      tooltip.dispose();
    });
  }

  private initialiseViewState() {
    this.viewState = (() => {
      if (this.isDropdownNav()) {
        return ViewState.Collapsed;
      }
      return ViewState.Collapsed === localStorage.getItem(storageViewStateID)
        ? ViewState.Collapsed
        : ViewState.Expanded;
    })();
  }

  private persistViewState() {
    if (this.viewState) {
      localStorage.setItem(storageViewStateID, this.viewState);
    }
  }

  private toggleViewState() {
    this.updateTargetClassLists("toggle", "toggle");
    this.viewState = (() => {
      switch (this.viewState) {
        case ViewState.Collapsed:
          return ViewState.Expanded;
        case ViewState.Expanded:
          return ViewState.Collapsed;
        default:
          throw new TypeError(
            `Unknown nav view state '${String(this.viewState)}'`
          );
      }
    })();
  }

  private getTargetElement(id: string): Element | undefined {
    return Reflect.get(this, `${id}Target`) as Element | undefined;
  }

  private getTargetElements(targetName: string): Element[] | undefined {
    return Reflect.get(this, `${targetName}Targets`) as Element[] | undefined;
  }

  private isDropdownNav() {
    const navElement = this.getTargetElement("nav");
    return (
      navElement && window.getComputedStyle(navElement).position === "absolute"
    );
  }

  private updateTargetClassLists(
    updateMethodName: TargetClassListUpdateMethodName,
    key: TargetClassListKey
  ) {
    targetNames.forEach((targetName) => {
      this.getTargetElements(targetName)?.forEach((element) => {
        targetClasses[targetName][key].forEach((targetClass) => {
          element.classList[updateMethodName](targetClass);
        });
      });
    });
  }
}
